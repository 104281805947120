﻿/*
--------------------------------------------------------------------------------
Authorization module
--------------------------------------------------------------------------------
*/

ori.addModule("authorization", {

    /**
     * Initialization
     */
    init: function() {
    },

    removeLoggedUserCookie: function() {
        $.removeCookie("OriLoggedUser", { path: "/" });
    }
});